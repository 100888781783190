import React from "react"
import oneChangeLogo from "./../assets/brands/one-change/icon.svg"
import UserCompanyLogo from "./../assets/brands/te/te-connectivity.svg"
import { Trans } from "react-i18next"
import useCurrentLang from "../hooks/useCurrentLang"

const Footer = () => {
  const { code: langCode } = useCurrentLang()

  return (
    <footer className="footer">
      <img
        src={oneChangeLogo}
        className="hide-on-small-only"
        alt="one change brand logo"
        style={{ height: 25 }}
      />
      <nav style={{ margin: "auto" }}>
        <ul className="footer__list">
          <li className="footer__list-item">
            <a
              className="footer__link small-text"
              href={`https://s3.eu-central-1.amazonaws.com/static-content.1change.co/MD_Term/Terms_${
                langCode === "zh" ? "HK" : langCode.toUpperCase()
              }.html`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans i18nKey="footer.trems_of_service" />
            </a>
          </li>
          <li className="footer__list-item">
            <a
              className="footer__link small-text"
              href={`https://s3.eu-central-1.amazonaws.com/static-content.1change.co/MD_Privacy/Policy_${
                langCode === "zh" ? "HK" : langCode.toUpperCase()
              }.html`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans i18nKey="footer.privacy_policy" />
            </a>
          </li>
          <li className="footer__list-item">
            <a
              className="footer__link small-text contact-us"
              href="mailto:grow@te.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans i18nKey="footer.contact_us" />
            </a>
          </li>
          <li className="footer__list-item">
            <a
              className="footer__link small-text"
              href={`https://s3.eu-central-1.amazonaws.com/static-content.1change.co/MD_Cookie/Cookie_${
                langCode === "zh" ? "HK" : langCode.toUpperCase()
              }.html`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans i18nKey="footer.cookie_policy" />
            </a>
          </li>
          <li className="footer__list-item last">
            <p className="small-text">1 Change Inc. © 2024</p>
          </li>
        </ul>
      </nav>
      <div className="footer__img_right">
        <img
          src={UserCompanyLogo}
          alt="one change brand logo"
          style={{ height: 35 }}
        />
      </div>
    </footer>
  )
}

export default Footer
