import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import { Trans } from "react-i18next"
import { generateRankText } from "../../utilities/formatting"
import { COMPANY_ID, SCOREBOARD_FILTER } from "../../constants"
import { GET_LEADERBOARD } from "../../gql"
import Throbber from "../throbber"
import { sortUnitsData } from "../../utilities/scoreboardHelpers"

const BusinessUnitsList = ({ limit, filter, userData }) => {
  const [businessUnits, setBusinessUnits] = useState([])

  const { data: businessUnitsData, loadingBusinessUnits } = useQuery(
    GET_LEADERBOARD,
    {
      variables: {
        pagination: {
          skip: 0,
          limit: limit,
        },
        groupBy: filter,
        countryFilter: userData.country,
        businessUnitFilter: "",
        sitesFilter: "",
        companyId: COMPANY_ID,
      },
    }
  )

  useEffect(() => {
    if (
      businessUnitsData &&
      businessUnitsData.getReportingLeaderboardData &&
      businessUnitsData.getReportingLeaderboardData.leaderboard
    ) {
      const data = businessUnitsData.getReportingLeaderboardData.leaderboard

      const sortedBusinessUnitsData = sortUnitsData(
        data,
        userData,
        "businessUnit"
      )
      setBusinessUnits(sortedBusinessUnitsData)
    }
  }, [businessUnitsData, loadingBusinessUnits])

  return (
    <div className="">
      {!businessUnits || businessUnits.length === 0 ? (
        <div className="sites_units_no_data">
          <Trans i18nKey="scoreboard.no_business_units_data" />
        </div>
      ) : (
        <div className="leader_board_list">
          {businessUnits.map((businessUnit, index) => (
            <div
              key={businessUnit.id}
              className="leader_board_member bu-row country_member"
            >
              <div className="info_one">
                <div>
                  <h6>{generateRankText(businessUnit.position)}</h6>
                </div>
                <div className="bu-name">{businessUnit.teamName}</div>
              </div>
              <div className="info_two score">
                {Number(businessUnit.points).toFixed(0)}
              </div>
            </div>
          ))}
        </div>
      )}
      {loadingBusinessUnits && filter === SCOREBOARD_FILTER.BUSINESS_UNIT ? (
        <Throbber relative loadingText="Fetching Business units.." />
      ) : (
        <></>
      )}
    </div>
  )
}

export default BusinessUnitsList
