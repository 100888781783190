import React from "react"
import JourneyCard from "./journey-card"
import Throbber from "./throbber"
import { useRecommendedJourneys } from "../utilities/hooks"
import { Trans } from "react-i18next"
import { Link } from "gatsby"
import { APP_ROUTES } from "../constants"

const RecommendedJourneys = ({ allJourneys, setDisplayResults }) => {
  const recommendedJourneys = useRecommendedJourneys(allJourneys)

  // The below effect ensures that the recommended journeys have loaded before the results are displayed on Home.
  // This is to prevent the results loading first (which they normally would, as they dont depend on API data), which creates a janky user experience.
  React.useEffect(() => {
    if (recommendedJourneys.length > 0 && setDisplayResults) {
      setDisplayResults(true)
    }
  }, [recommendedJourneys])

  return (
    <div className="row">
      <div className="row s12">
        <div
          className="for-text center"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h4>
            <Link to={APP_ROUTES.journeys}>
              <Trans i18nKey="recommendedjourneys.title" />
            </Link>
          </h4>
        </div>
      </div>
      <div className="col">
        <div
          className="row card__grid"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {recommendedJourneys.length > 0 ? (
            recommendedJourneys.map(journey => (
              <JourneyCard
                key={journey.contentful_id}
                {...journey}
                width="48%"
              />
            ))
          ) : (
            <Throbber relative loadingText="" />
          )}
        </div>
      </div>
    </div>
  )
}

export default RecommendedJourneys
