import React from "react"
import { useQuery } from "@apollo/react-hooks"
import { USER_INSPIRATION } from "../gql"
import InspirationCard from "./inspiration-card"
import { Trans } from "react-i18next"

const YourInspiration = ({ allJourneys, myData }) => {
  const [inspirationCards, setInspirationCards] = React.useState([])

  React.useEffect(() => {
    if (myData && myData.me) {
      // Get all the users active journeys
      const myJourneys = myData.me.journeys.filter(journey => journey.active)

      // For each journey, use the id to get the journey content needed for the inspiration cards.
      const mergedJourneys = myJourneys.map(myJourney => {
        const journeyContent = allJourneys.find(journey => {
          return myJourney.contentfulId === journey.contentful_id
        })
        if (journeyContent.inspiration) {
          let journey = {
            journeyTitle: journeyContent.title,
            headerColor: journeyContent.category.color,
            color: journeyContent.color,
            journeySlug: journeyContent.slug,
            illustration: journeyContent.illustration,
            ...journeyContent.inspiration,
          }
          return journey
        }
        return null
      })

      const hasInspiration = mergedJourneys.some(el => el !== null)
      if (hasInspiration) {
        // Merged journeys might contain null (if no inspiration exists for an active journey).
        // We only want to set the inspiration cards if one of the journeys has inspiration data.
        // Otherwise, the array might look like [null, null] and have an above 0 length (but still not have any useful data).
        setInspirationCards(mergedJourneys)
      }
    }
  }, [myData])

  return (
    <>
      {inspirationCards.length > 0 && (
        <div className="margin--bottom-100">
          <div className="row">
            <h1 className="big-title uppercase center no-margin--bottom">
              <Trans i18nKey="your-inspiration.title" />
            </h1>
            <p className="col s12 l8 offset-l2 center text">
              <Trans i18nKey="your-inspiration.sub_text" />
            </p>
          </div>
          <div className="card__grid">
            {inspirationCards.map((inspirationCard, idx) => {
              if (inspirationCard) {
                return <InspirationCard key={idx} {...inspirationCard} />
              } else {
                return null
              }
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default YourInspiration
