import gql from "graphql-tag"

const GET_SITES = gql`
  query GetSites {
    getSites {
      id
      country
      location
      countryCode
    }
  }
`;


export { GET_SITES }
