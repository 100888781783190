import React from "react"
import { Link } from "gatsby"

import LogoSvg from "../assets/images/1change-logo.svg"
import { useUserCompany } from "../utilities/hooks"
import LanguageSwitcher from "./language-switcher"

const Header = ({ path }) => {
  const userCompany = useUserCompany()

  return (
    <header className="intro-header">
      <nav className="intro-header__navigation">
        <Link
          to={`/${path && path.includes("/login") ? "welcome" : "login"}`} // If we are on the login page, go to Welcome. Otherwise, go to Login.
          className={`intro-header__navigation-logo ${
            !userCompany || (userCompany && !userCompany.showCompanyLogo)
              ? "center-block"
              : ""
          }`}
        >
          <img
            className={`intro-header__navigation-logo ${
              !userCompany || (userCompany && !userCompany.showCompanyLogo)
                ? "center-block"
                : ""
            }`}
            alt="1Change logo"
            src={LogoSvg}
          />
        </Link>
        {userCompany && userCompany.showCompanyLogo && userCompany.logo && (
          <img
            className="intro-header__company-logo"
            src={userCompany.logo.file.url}
            alt="Company logo"
          />
        )}
        <div className="language-switcher-container-intro">
          <LanguageSwitcher userData={null} />
        </div>
      </nav>
    </header>
  )
}

export default Header
