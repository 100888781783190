import React from "react"
import { Trans } from "react-i18next"

const ActivityProgress = ({
  progress,
  progressLeft,
  progressLoading,
  doActivity,
  undoActivity,
  isPreview,
  isRiddle,
}) => {
  const [steps, setSteps] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    const totalSteps = progress + progressLeft
    const tempSteps = Array.from(Array(totalSteps)).map((e, i) => i + 1)
    setSteps(tempSteps)
  }, [])

  React.useEffect(() => {
    // progressLoading is the loading state from Apollo when an activity mutation is activated.
    // However, to only disable the 'track' button on a particular activity when loading, we also need to keep some local loading state.
    // So when progressLoading changes to 'false', we also disable the local loading state, making the track button available again.
    if (!progressLoading) {
      setIsLoading(false)
    }
  }, [progressLoading])

  if (isPreview) {
    // The component is being rendered from an activity card, and should only support Tracking.
    return (
      <div className="activity--preview margin--top-16">
        <Tracking isPreview steps={steps} progress={progress} />
        {!isRiddle ? (
          <div className="activity__tracking__button">
            <button
              className="button button--small"
              onClick={() => {
                setIsLoading(true)
                doActivity()
              }}
              disabled={progressLoading && isLoading}
            >
              <Trans i18nKey="activity-progress.track" />
            </button>
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div>
      <div className="activity__progress__wrapper">
        <Tracking
          steps={steps}
          progress={progress}
          undoActivity={undoActivity}
        />
        {progressLeft > 0 ? (
          <button
            className="button button--activity"
            onClick={doActivity}
            disabled={progressLoading}
          >
            <Trans i18nKey="activity-progress.track" />
          </button>
        ) : null}
      </div>
    </div>
  )
}

const Tracking = ({ isPreview, steps, progress, undoActivity }) => (
  <div className={`${!isPreview ? "activity__progress__tracking" : ""}`}>
    {steps.map(step => {
      const isTracked = progress >= step
      return (
        <div
          key={step}
          onClick={() => {
            if (isTracked && undoActivity) {
              undoActivity()
            }
          }}
          className={`activity__progress ${
            isTracked ? "activity__progress--active" : ""
          } ${isPreview ? "activity__progress--preview" : ""}`}
          style={steps.length > 10 ? { marginBottom: 4 } : {}}
        >
          <span>
            {isTracked ? (
              isPreview ? (
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginBottom: -1 }}
                >
                  <path
                    d="M1.75717 5.707L5.39402 9.95L4.18202 11.364L0.545166 7.121L1.75717 5.707Z"
                    fill="black"
                  />
                  <path
                    d="M11.4548 2.87899L4.18197 11.364L2.96912 9.94999L10.2428 1.46399L11.4548 2.87899Z"
                    fill="black"
                  />
                </svg>
              ) : (
                <svg width="14" height="12" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#000" fillRule="evenodd">
                    <path d="M2.05 5.707L6.293 9.95l-1.414 1.414L.636 7.121z" />
                    <path d="M13.364 2.879l-8.485 8.485L3.464 9.95l8.486-8.486z" />
                  </g>
                </svg>
              )
            ) : (
              step
            )}
          </span>
        </div>
      )
    })}
  </div>
)

export default ActivityProgress
