import React from "react"
import { Link } from "gatsby"
import DOMPurify from "dompurify"
import { GET_TEAMS } from "../../gql/team"
import { useQuery } from "@apollo/react-hooks"
import Throbber from "../throbber"
import defaultAvatar from "../../assets/images/group.png"
import { Trans } from "react-i18next"

const dangerouslySetInnerHTML = teamValues => {
  const sanitizedHTML = DOMPurify.sanitize(teamValues)
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
}

const HomeTeam = () => {
  const { data, loading } = useQuery(GET_TEAMS, {
    variables: {
      pagination: {
        skip: 0,
        limit: 5,
      },
    },
  })

  const teamMemberData = (data && data.getTeams && data.getTeams.teams) || []

  return loading ? (
    <Throbber relative loadingText="Loading..." />
  ) : (
    <div className="small">
      <div className="for-text center">
        <h4>
          <Link to="/team">
            <Trans i18nKey="home-team-member.title" />
          </Link>
        </h4>
      </div>
      <div className="leader-board-body">
        <div className="leader_board_list">
          {teamMemberData.map((eachMember, index) => {
            const { teamValues, teamName, teamImage, id } = eachMember
            const imageData =
              teamImage && teamImage.path
                ? teamImage.path // Use image URL if available
                : defaultAvatar // Fallback to DefaultAvatar

            return (
              <div className="leader_board_member" key={id}>
                <div className="info_one">
                  <div>
                    <div className="profile_img">
                      <img
                        style={{
                          objectFit: "cover",
                          height: "inherit",
                          width: "inherit",
                        }}
                        src={imageData}
                        alt=""
                        className=""
                      />
                    </div>
                    <div className="profile_user">
                      <h5>{teamName}</h5>
                      <p>{dangerouslySetInnerHTML(teamValues)}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HomeTeam
