// LanguageSwitcher.js
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { LANGUAGES_FLAG } from "../constants"
import { hasLanguageBeenSet, markLanguageAsSet } from "../utilities/helpers"

const LanguageSwitcher = ({ userData }) => {
  const { i18n } = useTranslation()

  const changeLanguage = language => {
    i18n.changeLanguage(language)
  }

  const getFullLanguageCode = shortCode => {
    const fullCode = Object.keys(LANGUAGES_FLAG).find(key => key === shortCode)
    return fullCode || null // Return the full code, or null if not found
  }

  useEffect(() => {
    if (
      !hasLanguageBeenSet() &&
      userData &&
      userData.me &&
      userData.me.language
    ) {
      const fullCode = getFullLanguageCode(userData.me.language)
      if (fullCode) {
        changeLanguage(fullCode)
      }
      markLanguageAsSet()
    }
  }, [userData])

  return (
    <div className="language-switcher-container">
      <select
        id="languageSelector"
        onChange={e => changeLanguage(e.target.value)}
        value={i18n.language}
        className="language-dropdown"
      >
        {Object.entries(LANGUAGES_FLAG).map(([code, label]) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default LanguageSwitcher
