import gql from "graphql-tag"

const GET_BUSINESSUNITS = gql`
  query GetBusinessUnits($companyId: String!) {
    getBusinessUnits(companyId: $companyId) {
      id
      companyId
      segment
      name
    }
  }
`

export { GET_BUSINESSUNITS }
