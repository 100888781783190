import gql from "graphql-tag"

const ACTIVITY_PROGRESS = gql`
  query ActivityProgress($contentfulId: ID!) {
    activity(contentfulId: $contentfulId) {
      contentfulId
      progress
      progressLeft
      active
      completed
      __typename
    }
  }
`

const START_ACTIVITY = gql`
  mutation startActivity($contentfulId: ID!) {
    startActivity(contentfulId: $contentfulId) {
      contentfulId
      progress
      progressLeft
      active
      completed
      __typename
    }
  }
`

const DO_ACTIVITY = gql`
  mutation doActivity($contentfulId: ID!) {
    doActivity(contentfulId: $contentfulId) {
      contentfulId
      progress
      progressLeft
      active
      completed
      __typename
    }
  }
`

const REDO_ACTIVITY = gql`
  mutation redoActivity($contentfulId: ID!) {
    redoActivity(contentfulId: $contentfulId) {
      progress
      progressLeft
      active
      completed
    }
  }
`

const STOP_ACTIVITY = gql`
  mutation stopActivity($contentfulId: ID!) {
    stopActivity(contentfulId: $contentfulId) {
      contentfulId
      progress
      progressLeft
      active
      completed
      __typename
    }
  }
`

const UNDO_ACTIVITY = gql`
  mutation undoActivity($contentfulId: ID!) {
    undoActivity(contentfulId: $contentfulId) {
      contentfulId
      progress
      progressLeft
      active
      completed
      __typename
    }
  }
`

const ALL_JOURNEY_ACTIVITIES = gql`
  query JourneyActivities($contentfulId: ID!) {
    journey(contentfulId: $contentfulId) {
      activities {
        active
        progress
        progressLeft
        completed
        contentfulId
      }
    }
  }
`

export {
  ACTIVITY_PROGRESS,
  START_ACTIVITY,
  DO_ACTIVITY,
  REDO_ACTIVITY,
  STOP_ACTIVITY,
  UNDO_ACTIVITY,
  ALL_JOURNEY_ACTIVITIES,
}
