import { useStaticQuery, graphql } from "gatsby"

const Site = () => {
  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  return site
}

export { Site }
