import gql from "graphql-tag"

const GET_FEED = gql`
  query Feed(
    $pagination: Pagination
    $filterBy: String
    $contentfulId: String
    $activityContentfulId: String
  ) {
    feed(
      pagination: $pagination
      filterBy: $filterBy
      contentfulId: $contentfulId
      activityContentfulId: $activityContentfulId
    ) {
      posts: edges {
        id
        content
        contentfulId
        activityContentfulId
        localization {
          de_DE {
            content
          }
          en_US {
            content
          }
          zh_HK {
            content
          }
          es_ES {
            content
          }
          hu_HU {
            content
          }
          cs_CS {
            content
          }
          pt_PT {
            content
          }
          fr_FR {
            content
          }
          pl_PL {
            content
          }
          nl_NL {
            content
          }
          ja_JA {
            content
          }
          ko_KO {
            content
          }
          th_TH {
            content
          }
          it_IT {
            content
          }
        }
        date
        user {
          name
          teamName
          avatarNumber
          teamImage {
            path
          }
        }
        image {
          path
        }
        likes {
          email
          name
          teamName
          teamImage {
            path
          }
        }
        comments {
          content
          localization {
            de_DE {
              content
            }
            en_US {
              content
            }
            zh_HK {
              content
            }
            es_ES {
              content
            }
            hu_HU {
              content
            }
            cs_CS {
              content
            }
            pt_PT {
              content
            }
            fr_FR {
              content
            }
            pl_PL {
              content
            }
            nl_NL {
              content
            }
            ja_JA {
              content
            }
            ko_KO {
              content
            }
            th_TH {
              content
            }
            it_IT {
              content
            }
          }
          user {
            name
            teamName
            avatarNumber
            teamImage {
              path
            }
          }
        }
      }
    }
  }
`

const CREATE_POST = gql`
  mutation CreatePost(
    $content: String!
    $contentfulId: String
    $activityContentfulId: String
    $image: Upload
  ) {
    post(
      content: $content
      contentfulId: $contentfulId
      activityContentfulId: $activityContentfulId
      image: $image
    ) {
      id
      content
      contentfulId
      activityContentfulId
      localization {
        de_DE {
          content
        }
        en_US {
          content
        }
        zh_HK {
          content
        }
        es_ES {
          content
        }
        hu_HU {
          content
        }
        cs_CS {
          content
        }
        pt_PT {
          content
        }
        fr_FR {
          content
        }
        pl_PL {
          content
        }
        nl_NL {
          content
        }
        ja_JA {
          content
        }
        ko_KO {
          content
        }
        th_TH {
          content
        }
        it_IT {
          content
        }
      }
      date
      user {
        name
        avatarNumber
      }
    }
  }
`

const REACT_ON_POST = gql`
  mutation ReactOnPost($postId: ID!) {
    reactOnPost(postId: $postId) {
      id
      content
      date
      user {
        name
        avatarNumber
        teamName
      }
    }
  }
`

const COMMENT_ON_POST = gql`
  mutation CommentOnPost($content: String!, $postId: ID!) {
    comment(content: $content, postId: $postId) {
      id
      content
      localization {
        de_DE {
          content
        }
        en_US {
          content
        }
        zh_HK {
          content
        }
        es_ES {
          content
        }
        hu_HU {
          content
        }
        cs_CS {
          content
        }
        pt_PT {
          content
        }
        fr_FR {
          content
        }
        pl_PL {
          content
        }
        nl_NL {
          content
        }
        ja_JA {
          content
        }
        ko_KO {
          content
        }
        th_TH {
          content
        }
        it_IT {
          content
        }
      }
      date
      user {
        name
        avatarNumber
      }
    }
  }
`

export { GET_FEED, CREATE_POST, REACT_ON_POST, COMMENT_ON_POST }
