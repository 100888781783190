import React from "react"
import Windrose from "./windrose"
import ResilienceModel from "../assets/images/resilience-schema.svg"
import { Trans } from "react-i18next"

const ResilienceResultComponent = ({ resilienceTest, allJourneys }) => {
  const categoriesOrder = new Set(["Heart", "Spirit", "Mind", "Body"])

  const organizeJourneys = () => {
    const journeyOrder = []
    categoriesOrder.forEach(category => {
      for (let index = 0; index < allJourneys.length; index++) {
        if (allJourneys[index].category && allJourneys[index].category.title === category) {
          journeyOrder.push(allJourneys[index].title)
        }
      }
    })
    return journeyOrder
  }
  const journeyOrder = organizeJourneys()

  const getScoreProcentage = resilienceTest => {
    const resilienceScore = resilienceTest.testResult.overallScore || 0
    const maxScore = 240
    const scoreProcentage = (resilienceScore / maxScore) * 100
    return scoreProcentage < 0 ? 0 : scoreProcentage.toFixed(0)
  }

  const scoreResultDescription = resilienceTest => {
    const score = getScoreProcentage(resilienceTest)

    if (score >= 80) {
      return "score_description.score_above_80"
    } else if (score >= 63 && score <= 79) {
      return "score_description.score_above_63"
    } else if (score >= 45 && score <= 62) {
      return "score_description.score_above_45"
    } else if (score >= 26 && score <= 44) {
      return "score_description.score_above_26"
    }
    return "score_description.score_below_26"
  }

  return (
    <div className="row">
      <div className="col s12">
        <h1 className="big-title uppercase center-align margin--bottom-30">
        <Trans i18nKey="resilience-result-component.title" />
        </h1>
        <p className="subtitle uppercase no-margin center-align">
        <Trans i18nKey="resilience-result-component.score_text" />  {getScoreProcentage(resilienceTest)}
        </p>
      </div>

      <div className="col s12 resilience__model-wrapper">
        <div className="resilience__model-graph ">
          <Windrose
            journeys={allJourneys}
            journeyOrder={journeyOrder}
            questions={resilienceTest.questions}
          />
        </div>
        <div className="resilience__model-graph resilience__model-image-wrapper">
          <img
            className="resilience__model-image"
            src={ResilienceModel}
            alt=""
          />
        </div>
      </div>
      <div className="col l8 offset-l2 ">
        <p className="text no-margin">
          <Trans i18nKey={scoreResultDescription(resilienceTest)} />
        </p>
      </div>
    </div>
  )
}

export default ResilienceResultComponent
