import gql from "graphql-tag"

const ME = gql`
  query Me {
    me {
      id
      email
      company
      name
      teamName
      teamValues
      language
      teamImage {
        name
        path
        id
      }
      totalBonusScore
      bonusScore {
        points
        id
        comment
        type
        backgroundColor
        localization {
          de_DE {
            content
          }
          en_US {
            content
          }
          zh_HK {
            content
          }
          es_ES {
            content
          }
          hu_HU {
            content
          }
          cs_CS {
            content
          }
          pt_PT {
            content
          }
          fr_FR {
            content
          }
          pl_PL {
            content
          }
          nl_NL {
            content
          }
          ja_JA {
            content
          }
          ko_KO {
            content
          }
          th_TH {
            content
          }
          it_IT {
            content
          }
        }
      }
      teamAccount
      progress {
        points
        pointsToNextLevel
        level
      }
      businessUnit {
        id
        name
        companyId
      }
      sites {
        id
        country
        location
      }
      country
    }
  }
`

const TEAM_ME = gql`
  query Me {
    me {
      id
      email
      teamAccount
      language
      businessUnit {
        id
        name
        companyId
      }
      country
    }
  }
`

const USER_PROGRESS = gql`
  query UserProgress {
    me {
      id
      progress {
        points
      }
    }
  }
`

const USER_ID_AND_COMPANY = gql`
  query UserIdAndCompany {
    me {
      id
      company
    }
  }
`

const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $newPassword: String!) {
    updateUser(id: $id, newPassword: $newPassword) {
      id
      email
      company
      progress {
        points
        pointsToNextLevel
        level
      }
    }
  }
`

const UPDATE_TEAM = gql`
  mutation UpdateTeam($teamName: String!, $teamImage: Upload) {
    updateTeam(teamName: $teamName, teamImage: $teamImage) {
      teamName
      teamValues
      id
      teamImage {
        path
        name
        id
      }
    }
  }
`

const USER_JOURNEYS_ACTIVITIES = gql`
  query UserJourneysAndActivities {
    me {
      id
      journeys {
        contentfulId
        active
        activities {
          contentfulId
          active
          progress
          progressLeft
          completed
        }
      }
    }
  }
`

const USER_ACTIVITIES = gql`
  query UserActivities {
    me {
      id
      activities {
        contentfulId
        active
        progress
        progressLeft
        completed
      }
    }
  }
`

const USER_INSPIRATION = gql`
  query UserInspiration {
    me {
      id
      journeys {
        contentfulId
        active
      }
    }
  }
`

const USER_JOURNEYS = gql`
  query UserJourneys {
    me {
      id
      company
      journeys {
        contentfulId
        title
        title_cs
        title_de
        title_es
        title_fr
        title_hu
        title_it
        title_ja
        title_ko
        title_nl
        title_pl
        title_pt
        title_th
        title_hk
        active
        activities {
          contentfulId
          completed
        }
      }
    }
  }
`

const RECOMMENDED_JOURNEYS = gql`
  query RecommendedJourneys {
    resilienceTest {
      testResult {
        journeys {
          contentfulId
          company
          title
          #title_cs
          #title_de
          #title_es
          #title_fr
          #title_hu
          #title_it
          #title_ja
          #title_ko
          #title_nl
          #title_pl
          #title_pt
          #title_th
          #title_hk
        }
      }
    }
  }
`

export {
  ME,
  UPDATE_USER,
  USER_PROGRESS,
  USER_ID_AND_COMPANY,
  USER_ACTIVITIES,
  USER_JOURNEYS,
  USER_JOURNEYS_ACTIVITIES,
  USER_INSPIRATION,
  RECOMMENDED_JOURNEYS,
  UPDATE_TEAM,
  TEAM_ME,
}
