import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useMutation, useQuery } from "@apollo/react-hooks"
import {
  USER_ID_AND_COMPANY,
  ACTIVITY_PROGRESS,
  START_ACTIVITY,
  DO_ACTIVITY,
  REDO_ACTIVITY,
  STOP_ACTIVITY,
  UNDO_ACTIVITY,
  USER_PROGRESS,
  USER_JOURNEYS_ACTIVITIES,
} from "../gql"
import { getJourneyCardDetails } from "./helpers"
import { getUserJourneysAndActivities } from "../queries/user"

export const useLatestWeekJourneys = allJourneys => {
  const [myData, setMyData] = useState(null)
  const [latestWeekJourneys, setLatestWeekJourneys] = useState([])
  const {
    // isLoading: uJALoading,
    isError: uJAIsError,
    data: uJAData,
    error: uJAError,
    // isFetching: upIsFetching,
    refetch: fetchUJAData,
  } = getUserJourneysAndActivities({
    service: "",
    relativeUrl: "/admin-journey",
    params: {},
  })

  useEffect(() => {
    fetchUJAData()
  }, [])

  useEffect(() => {
    if (uJAData && uJAData.data) {
      setMyData(uJAData.data)
    }
  }, [uJAData])

  useEffect(() => {
    if (uJAIsError) {
      console.log("Error received in userProgress: ", uJAError)
    }
  }, [uJAIsError])

  useEffect(() => {
    if (myData && myData.me) {
      const filtered = myData.me.journey.filter(journey => journey.company) // Remove the company specific journeys
      const journeysToRecommend = getJourneyCardDetails(filtered, allJourneys)
      const weeksFilteredVals = journeysToRecommend.filter(
        jour => jour.subtitle.subtitle.includes("Week 3")
        // || jour.subtitle.subtitle.includes("Week") // un comment this after the client has tested.
      )
      if (weeksFilteredVals.length > 0) {
        const lastElementSubtitle =
          weeksFilteredVals[weeksFilteredVals.length - 1].subtitle.subtitle
        const latestWeekJourneys = weeksFilteredVals.filter(
          jour => jour.subtitle.subtitle === lastElementSubtitle
        )
        setLatestWeekJourneys(latestWeekJourneys)
      }
    }
  }, [myData])

  return latestWeekJourneys
}

export const useUserCompany = () => {
  const {
    allContentfulCompany: { nodes: companies },
  } = useStaticQuery(graphql`
    {
      allContentfulCompany {
        nodes {
          contentful_id
          logo {
            file {
              url
            }
          }
          showCompanyLogo
        }
      }
    }
  `)
  const { data: userData } = useQuery(USER_ID_AND_COMPANY)
  const [userCompany, setUserCompany] = React.useState(null)

  React.useEffect(() => {
    if (userData && userData.me) {
      const company = companies.find(company => {
        return company.contentful_id === userData.me.company
      })
      setUserCompany(company)
    }
  }, [userData])

  return userCompany
}

export const useActivityMutations = contentfulId => {
  const [data, setData] = React.useState(null)
  let variables = {}
  if (contentfulId) {
    variables = {
      contentfulId: contentfulId,
    }
  }

  const refetch = [
    {
      query: ACTIVITY_PROGRESS,
      variables: {
        contentfulId: contentfulId,
      },
    },
    {
      query: USER_PROGRESS,
    },
    {
      query: USER_JOURNEYS_ACTIVITIES,
    },
  ]

  const [startActivity] = useMutation(START_ACTIVITY, {
    variables: variables,
    refetchQueries: refetch,
    onCompleted({ startActivity }) {
      setData(startActivity)
    },
  })

  const [doActivity, { loading }] = useMutation(DO_ACTIVITY, {
    variables: variables,
    refetchQueries: refetch,
    onCompleted({ doActivity }) {
      setData(doActivity)
    },
  })

  const [redoActivity] = useMutation(REDO_ACTIVITY, {
    variables: variables,
    refetchQueries: refetch,
    onCompleted({ redoActivity }) {
      setData(redoActivity)
    },
  })

  const [stopActivity] = useMutation(STOP_ACTIVITY, {
    variables: variables,
    refetchQueries: refetch,
    onCompleted({ stopActivity }) {
      setData(stopActivity)
    },
  })

  const [undoActivity] = useMutation(UNDO_ACTIVITY, {
    variables: variables,
    refetchQueries: refetch,

    onCompleted({ undoActivity }) {
      setData(undoActivity)
    },
  })

  return {
    startActivity,
    doActivity,
    redoActivity,
    stopActivity,
    undoActivity,
    loading,
    data,
  }
}
