import React from "react"
import defaultAvatar from "../../assets/images/sample-avatar.svg"
import { Link } from "gatsby"
import Throbber from "../throbber"
import { GET_FEED } from "../../gql"
import { useQuery } from "@apollo/react-hooks"
import { toDay, toHours } from "../../utilities/formatting"
import { FEED_FILTER } from "../../constants"
import { Trans } from "react-i18next"

const HomeFeed = () => {
  const { data, loading } = useQuery(GET_FEED, {
    variables: {
      pagination: {
        skip: 0,
        limit: 5,
      },
      filterBy: FEED_FILTER.GLOBAL,
    },
  })
  const feeds = (data && data.feed && data.feed.posts && data.feed.posts) || []

  return loading ? (
    <Throbber relative loadingText="Loading..." />
  ) : (
    <div className="large">
      <div className="for-text no-padding center">
        <h4>
          <Link to="/feed">
            <Trans i18nKey="home-latest-feed.title" />
          </Link>
        </h4>
      </div>
      <div className="feed-body">
        <div className="feed-list">
          {feeds.map(feed => {
            const { user, content, date: feedDate, image, id } = feed
            const date = new Date(feedDate)
            const day = toDay(date)
            const hours = toHours(date)
            const imageData =
              image && image.path
                ? image.path // Use image URL if available
                : "" // Fallback to DefaultAvatar

            return (
              <div className="feed" key={id}>
                <div>
                  {imageData ? (
                    <div className="profile_img">
                      <img src={imageData} alt="" className="feed_img" />
                    </div>
                  ) : null}
                  <div className="profile_user">
                    <h5>{user.teamName ? user.teamName : user.name}</h5>
                    <p>
                      {day} at {hours}{" "}
                    </p>
                  </div>
                </div>
                <div>
                  <p>{content}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HomeFeed
