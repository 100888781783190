import gql from "graphql-tag"

const QUIZ_RESULT_JOURNEYS = gql`
  query QuizResult {
    resilienceTest {
      testResult {
        journeys {
          contentfulId
        }
      }
    }
  }
`

export { QUIZ_RESULT_JOURNEYS }
