import gql from "graphql-tag"

const SIGN_IN = gql`
  mutation SignIn($emailOrUserName: String!, $password: String!) {
    signIn(emailOrUserName: $emailOrUserName, password: $password) {
      token
      changePassword
    }
  }
`

const SIGN_UP = gql`
  mutation SignUp(
    $email: String!
    $userName: String!
    $password: String!
    $name: String!
    $company: String!
    $country: String!
    $teamAccount: Boolean!
    $businessUnit: String
    $sites: String
    $language: String
  ) {
    signUp(
      email: $email
      userName: $userName
      password: $password
      name: $name
      company: $company
      country: $country
      teamAccount: $teamAccount
      businessUnit: $businessUnit
      sites: $sites
      language: $language
    ) {
      token
    }
  }
`

const GET_AUTH_TOKEN = gql`
  query getLoggedIn {
    localAuthToken @client
    sessionAuthToken @client
  }
`

export { SIGN_IN, GET_AUTH_TOKEN, SIGN_UP }
