import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import { RESILIENCE_TEST } from "../gql"
import YourActivities from "../components/your-activities"
import YourInspiration from "../components/your-inspiration"
import ResilienceResultComponent from "../components/resilience-result-component"
import RecommendedJourneys from "../components/recommended-journeys"
import Intercom from "react-intercom"
import Throbber from "../components/throbber"
import HomeLeaderBoard from "../components/home/home-leaderboard"
import HomeFeed from "../components/home/home-feed"
import HomeTeam from "../components/home/home-team"
import TETorch from "./../assets/brands/te/te-torch.png"
import { Trans } from "react-i18next"
import { withTranslation } from "react-i18next"
import { isFeatureEnabled } from "../utilities/features"
import LatestWeekActivities from "../components/latest-week-activities"
import { getUserJourneysAndActivities } from "../queries/user"

// The query below uses a fragment defined in the gatsby_queries folder to keep the required activity properties DRY.
export const allJourneysWithActivities = graphql`
  query USER_JOURNEYS_ACTIVITIES {
    allContentfulJourney {
      nodes {
        ...JourneyWithActivities
      }
    }
  }
`

const HomebaseOld = ({ data, location }) => {
  const {
    allContentfulJourney: { nodes: journeys },
  } = data
  const [displayResults, setDisplayResults] = React.useState(false)

  const { data: questionsData } = useQuery(RESILIENCE_TEST, {
    fetchPolicy: "cache-and-network",
  })

  const [myData, setMyData] = useState(null)
  const {
    isLoading: uJALoading,
    isError: uJAIsError,
    data: uJAData,
    error: uJAError,
    // isFetching: upIsFetching,
    refetch: fetchUJAData,
  } = getUserJourneysAndActivities({
    service: "",
    relativeUrl: "/admin-journey",
    params: {},
  })

  useEffect(() => {
    fetchUJAData()
  }, [])

  useEffect(() => {
    if (uJAData && uJAData.data) {
      setMyData(uJAData.data)
    }
  }, [uJAData])

  useEffect(() => {
    if (uJAIsError) {
      console.log("Error received in userProgress: ", uJAError)
    }
  }, [uJAIsError])

  // if (questionsData && !questionsData.resilienceTest.completed) {
  //   // If the test is not done, send the user to the resilience intro page
  //   navigate("/resilience-intro")
  // }

  return (
    <Layout path={location.pathname}>
      {uJALoading ? (
        <Throbber relative loadingText="Loading..." />
      ) : (
        <>
          <YourActivities allJourneys={journeys} myData={myData} />
          <YourInspiration allJourneys={journeys} myData={myData} />
          <div>
            {questionsData && questionsData.resilienceTest && (
              <RecommendedJourneys
                allJourneys={journeys}
                resilienceTest={questionsData.resilienceTest}
                setDisplayResults={setDisplayResults}
              />
            )}
          </div>
          <div className="margin--top-100">
            {questionsData &&
              questionsData.resilienceTest &&
              displayResults && (
                <ResilienceResultComponent
                  allJourneys={journeys}
                  resilienceTest={questionsData.resilienceTest}
                />
              )}
          </div>
        </>
      )}

      <Intercom appID="hvf7ng5v" />
    </Layout>
  )
}

const HomebaseNew = ({ data, location }) => {
  const {
    allContentfulJourney: { nodes: journeys, loading },
  } = data

  return (
    <Layout path={location.pathname}>
      {loading ? (
        <Throbber relative loadingText="Loading..." />
      ) : (
        <>
          <section className="home-base-one one-div">
            <div className="main-container-flex">
              <img src={TETorch} alt="te-torch" className="torch-image" />
              <h1 className="home-title big-title">
                <Trans i18nKey={"home-title.title"} />
              </h1>
            </div>
          </section>
          <section className="home-base-two two-div">
            <div className="main-container">
              <div className="large">
                <LatestWeekActivities allJourneys={journeys} />
              </div>
              <HomeLeaderBoard />
            </div>
          </section>
          <section className="two-div">
            <div className="main-container">
              <HomeFeed />
              <HomeTeam />
            </div>
          </section>
        </>
      )}

      <Intercom appID="hvf7ng5v" />
    </Layout>
  )
}

const Homebase = ({ data, location }) => {
  const Home = isFeatureEnabled("/homebase-new") ? HomebaseNew : HomebaseOld
  return <Home data={data} location={location} />
}

export default withTranslation()(Homebase)
