/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import api from "../utilities/api";

const apiPostFunc = ({ service, body, relativeUrl = "", params = {} }) =>
  api
    .setMethod()
    .post()
    .setBody(body)
    .setService(service)
    .process(relativeUrl, params);

const apiGetFunc = ({ service, relativeUrl = "", params = {} }) =>
  api.setMethod().get().setService(service).process(relativeUrl, params);

const apiPutFunc = ({ service, body, relativeUrl = "", params = {} }) =>
  api
    .setMethod()
    .put()
    .setBody(body)
    .setService(service)
    .process(relativeUrl, params);

const apiPatchFunc = ({ service, body, relativeUrl = "", params = {} }) =>
  api
    .setMethod()
    .patch()
    .setBody(body)
    .setService(service)
    .process(relativeUrl, params);

const apiDeleteFunc = ({ service, relativeUrl = "", params = {} }) =>
  api.setMethod().delete().setService(service).process(relativeUrl, params);

const uploadFilesFunc = async ({ body, resUrl, dataType }) => {
  const fileInstance = axios.create({
    timeout: 30000,
  });
  const headersList = {
    Accept: "*/*",
    // 'Content-Type': dataType === 'pdf' ? 'application/pdf' : `image/${dataType}`,
    "Content-Type": "application/octet-stream",
  };

  const reqOptions = {
    url: resUrl,
    method: "PUT",
    headers: headersList,
    data: body,
  };
  const response = await fileInstance.request(reqOptions);
  return response;
};

export {
  apiPostFunc,
  apiGetFunc,
  apiPutFunc,
  apiPatchFunc,
  apiDeleteFunc,
  uploadFilesFunc,
};
