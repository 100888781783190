import gql from "graphql-tag"

const GET_TEAMS_MEMBERS = gql`
  query getMembers($pagination: Pagination) {
    getMembers(pagination: $pagination) {
      members: members {
        id
        name
        email
        image {
          path
        }
      }
    }
  }
`

const UPDATE_MEMBER = gql`
  mutation editMember(
    $id: ID!
    $name: String!
    $email: String!
    $image: Upload
  ) {
    editMember(memberId: $id, name: $name, email: $email, image: $image) {
      id
      name
      email
    }
  }
`

const ADD_MEMBER = gql`
  mutation addMember($name: String!, $email: String!, $image: Upload) {
    addMember(name: $name, email: $email, image: $image) {
      id
      name
      email
    }
  }
`

const DELETE_MEMBER = gql`
  mutation removeMember($id: ID!) {
    removeMember(memberId: $id)
  }
`

const GET_TEAMS = gql`
  query getTeams($pagination: Pagination) {
    getTeams(pagination: $pagination) {
      pageInfo: pageInfo {
        limit
        skip
        total
      }
      teams: teams {
        id
        teamName
        teamValues
        createdAt
        teamImage {
          path
          __typename
        }
        __typename
      }
      __typename
    }
  }
`

export {
  GET_TEAMS_MEMBERS,
  UPDATE_MEMBER,
  DELETE_MEMBER,
  ADD_MEMBER,
  GET_TEAMS,
}
