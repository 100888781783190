import gql from "graphql-tag"

const RESILIENCE_TEST = gql`
  query ResilienceTest {
    resilienceTest {
      questions {
        contentfulId
        content
        answer
        journey {
          title
        }
        category {
          title
        }
      }
      testResult {
        overallScore
        journeys {
          contentfulId
          activities {
            contentfulId
            completed
            progress
            progressLeft
            active
          }
          score
        }
      }
      completed
    }
  }
`

const ANSWER_QUESTION = gql`
  mutation AnswerQuestion($contentfulId: ID!, $answer: Int!) {
    answerQuestion(contentfulId: $contentfulId, answer: $answer) {
      contentfulId
      answer
    }
  }
`

export { RESILIENCE_TEST, ANSWER_QUESTION }
