import React, { useState, useEffect } from "react"
import ActivityCardHome from "./activity-card-home"
import Throbber from "./throbber"
import { useLatestWeekJourneys } from "../utilities/hooks"
import { Trans } from "react-i18next"
import { Link } from "gatsby"
import { APP_ROUTES } from "../constants"

const LatestWeekActivities = ({ allJourneys }) => {
  const latestJourneys = useLatestWeekJourneys(allJourneys)
  const [latestActivities, setLatestActivities] = useState([])

  const extractActivities = journeys => {
    return journeys.reduce((acc, journey) => {
      const activities = journey.activities.map(activity => ({
        ...activity,
        journeyTitle: journey.title,
        journeyId: journey.contentful_id,
        journeyColor: journey.color,
        journeySlug: journey.slug,
      }))
      return acc.concat(activities)
    }, [])
  }

  // The below effect ensures that the latest journeys have loaded before the results are displayed on Home.
  // This is to prevent the results loading first (which they normally would, as they dont depend on API data), which creates a janky user experience.
  useEffect(() => {
    if (latestJourneys.length > 0) {
      const activities = extractActivities(latestJourneys)
      setLatestActivities(activities)
    }
  }, [latestJourneys])

  return (
    <div className="row">
      <div className="row s12">
        <div
          className="for-text center"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h4>
            <Link to={APP_ROUTES.journeys}>
              <Trans i18nKey="recommendedjourneys.title-new" />
            </Link>
          </h4>
        </div>
      </div>
      <div className="col">
        <div
          className="row card__grid"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {latestActivities.length > 0 ? (
            latestActivities
              .sort((a, b) => a.journeyTitle.localeCompare(b.journeyTitle))
              .map(activity => (
                <ActivityCardHome
                  key={activity.contentfulId}
                  {...activity}
                  width="31%"
                />
              ))
          ) : (
            <Throbber relative loadingText="" />
          )}
        </div>
      </div>
    </div>
  )
}

export default LatestWeekActivities
