import React from "react"
import { Trans, withTranslation } from "react-i18next"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { DO_ACTIVITY, USER_PROGRESS, USER_JOURNEYS_ACTIVITIES } from "../gql"
import ActivityCard from "./activity-card"

const YourActivities = ({ allJourneys, myData }) => {
  const [doActivity, { loading: progressLoading }] = useMutation(DO_ACTIVITY, {
    refetchQueries: [
      {
        query: USER_JOURNEYS_ACTIVITIES,
      },
      {
        query: USER_PROGRESS,
      },
    ],
  })
  const [allActivities, setAllActivities] = React.useState([])

  React.useEffect(() => {
    if (myData && myData.me) {
      const { journeys } = myData.me
      // Locate all activities that the user has activated.
      const myJourneys = journeys.filter(journey => journey.active)
      let myActivities = []
      myJourneys.forEach(myJourney => {
        myJourney.activities.forEach(myActivity => {
          if (myActivity.active) {
            myActivities.push(myActivity)
          }
        })
      })

      let mergedActivities = []
      // Loop through each journey from Contentful.
      allJourneys.forEach(journey => {
        // For each journey, loop through the activities as well and find out if the activity exists in the users active activities.
        // If this is the case, combine all the activity content with the users activity progress, and the necessary journey data.
        journey.activities.forEach(journeyActivity => {
          const activeActivity = myActivities.find(myActivity => {
            return myActivity.contentfulId === journeyActivity.contentful_id
          })
          if (activeActivity) {
            let mergedActivity = {
              journeyColor: journey.color,
              journeyTitle: journey.title,
              journeySlug: journey.slug,
              journeyIllustration: journey.illustration,
              headerColor: journey.category.color,
              contentfulId: journeyActivity.contentful_id,
              ...journeyActivity, // The activity specific data like title, illustration etc.
              ...activeActivity, // The activity progress, active/completed status etc.
              ...journey,
            }
            mergedActivities.push(mergedActivity)
          }
        })
      })
      setAllActivities(mergedActivities)
    }
  }, [myData])

  const onDoActivity = contentfulId => {
    const variables = {
      contentfulId: contentfulId,
    }
    doActivity({
      variables: variables,
    })
  }

  const { me } = myData
  return (
    <>
      {allActivities.length > 0 && (
        <div className="margin--bottom-100">
          <div className="row">
            <h1 className="big-title uppercase center no-margin--bottom">
              <Trans i18nKey="your-activities.title" />
            </h1>
            <p className="col s12 l8 offset-l2 center text">
              <Trans i18nKey="your-activities.sub_text" />
            </p>
          </div>
          <div className="row card__grid">
            {allActivities.map(activity => (
              <ActivityCard
                key={activity.contentfulId}
                {...activity}
                onDoActivity={onDoActivity}
                progressLoading={progressLoading}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default withTranslation()(YourActivities)
