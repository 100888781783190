import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import ActivityProgress from "./activity-progress"
import { Trans, withTranslation } from "react-i18next"
import { APP_ROUTES } from "../constants/route"
import useCurrentLang from "../hooks/useCurrentLang"

const ActivityCardHome = ({
  onDoActivity,
  progressLoading,
  contentfulId,
  title,
  illustration,
  subtitle,
  journeyIllustration,
  journeyColor,
  slug,
  journeySlug,
  active,
  progress,
  progressLeft,
  width,
  ...rest
}) => {
  const { code: langCode } = useCurrentLang()
  let imgSrc = illustration && illustration.file && illustration.file.url
  if (!imgSrc) {
    imgSrc = journeyIllustration
      ? journeyIllustration.file && journeyIllustration.file.url
      : null
  }

  return (
    <div
      key={rest.key}
      className="card__wrapper"
      style={{ backgroundColor: journeyColor, width: width }}
    >
      <img className="card__image margin--top-24" src={imgSrc} alt="" />
      <div className="card__text">
        <h2 className="card__title no-margin--bottom margin--top-16 card__title--min-height-home">
          {rest[`title_${langCode === "zh" ? "hk" : langCode}`] || title}
        </h2>
        <p
          className={cx("card__subtitle", {
            "card__subtitle--min-height-home": true,
          })}
        >
          {rest[`subtitle_${langCode === "zh" ? "hk" : langCode}`] || subtitle}
        </p>
        <Link
          to={`${APP_ROUTES.journeys}/${journeySlug}/${slug}`}
          className="button card__button "
        >
          <Trans i18nKey="activity-cards.explore" />
        </Link>
        {active && (
          <ActivityProgress
            doActivity={() => onDoActivity(contentfulId)}
            progressLoading={progressLoading}
            isPreview
            progress={progress}
            progressLeft={progressLeft}
          />
        )}
      </div>
    </div>
  )
}

export default withTranslation()(ActivityCardHome)
