import gql from "graphql-tag"

const GET_LEADERBOARD = gql`
  query Leaderboard(
    $pagination: Pagination
    $groupBy: String
    $countryFilter: String
    $businessUnitFilter: String
    $sitesFilter: String
    $companyId: String
  ) {
    getReportingLeaderboardData(
      pagination: $pagination
      groupBy: $groupBy
      countryFilter: $countryFilter
      businessUnitFilter: $businessUnitFilter
      sitesFilter: $sitesFilter
      companyId: $companyId
    ) {
      leaderboard {
        id
        teamImage {
          path
        }
        teamName
        points
        position
      }
    }
  }
`

export { GET_LEADERBOARD }
