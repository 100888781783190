import { useStaticQuery, graphql } from "gatsby"

const Journey = () => {
  const { allContentfulJourney } = useStaticQuery(
    graphql`
      {
        allContentfulJourney {
          nodes {
            contentful_id
            title
            title_cs
            title_de
            title_es
            title_fr
            title_hu
            title_it
            title_ja
            title_ko
            title_nl
            title_pl
            title_pt
            title_th
            title_hk
            companySpecific {
              title
              contentful_id
            }
            subtitle {
              subtitle
            }
            subtitle_cs {
              subtitle_cs
            }
            subtitle_de {
              subtitle_de
            }
            subtitle_es {
              subtitle_es
            }
            subtitle_fr {
              subtitle_fr
            }
            subtitle_hu {
              subtitle_hu
            }
            subtitle_it {
              subtitle_it
            }
            subtitle_ja {
              subtitle_ja
            }
            subtitle_ko {
              subtitle_ko
            }
            subtitle_nl {
              subtitle_nl
            }
            subtitle_pl {
              subtitle_pl
            }
            subtitle_pt {
              subtitle_pt
            }
            subtitle_th {
              subtitle_th
            }
            subtitle_hk {
              subtitle_hk
            }
            color
            slug
            illustration {
              file {
                url
              }
            }
            category {
              color
              title
              #title_cs
              title_de
              #title_es
              #title_fr
              #title_hu
              #title_it
              #title_ja
              #title_ko
              #title_nl
              #title_pl
              #title_pt
              #title_th
              #title_hk
            }
            ...JourneyWithActivities
          }
        }
      }
    `
  )
  return allContentfulJourney
}

export { Journey }
