import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import { Trans } from "react-i18next"

const Windrose = ({ journeys, showCategories, questions }) => {
  useEffect(() => {
    HighchartsMore(Highcharts)

    Highcharts.chart("windrose-container", {
      series: [
        {
          data: getJourneyData(),
        },
      ],
      chart: {
        polar: true,
        type: "column",
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
      title: {
        text: "",
      },
      pane: {
        size: "100%",
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        tickLength: 0,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          enabled: false,
        },
      },
      yAxis: {
        min: 0,
        max: 20,
        endOnTick: false,
        showLastLabel: false,
        title: {
          text: "",
        },
        reversedStacks: false,
        lineWidth: 0,
        lineColor: "#fff",
        gridLineColor: "transparent",
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: "normal",
          shadow: false,
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0,
        },
        column: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    })
  }, [])

  const getJourneyColor = journeyTitle => {
    for (let index = 0; index < journeys.length; index++) {
      if (journeys[index].title === journeyTitle) {
        return journeys[index].color
      }
    }
    return "#000"
  }

  const getJourneyPoints = journeyTitle => {
    let journeyScore = 0
    const journeyQuestions = questions.filter(
      question => question.journey.title === journeyTitle
    )
    for (let index = 0; index < journeyQuestions.length; index++) {
      journeyScore += journeyQuestions[index].answer
    }
    return journeyScore
  }

  const getJourneyData = () => {
    const journeyOrder = [
      "Emotions",
      "Relationships",
      "Vulnerability",
      "Purpose",
      "Generosity",
      "Presence",
      "Efficiency",
      "Mindset",
      "Meditation",
      "Movement",
      "Nutrition",
      "Recovery",
    ]
    let journeysData = []
    for (let index = 0; index < journeyOrder.length; index++) {
      journeysData.push({
        color: getJourneyColor(journeyOrder[index]),
        name: journeyOrder[index],
        y: getJourneyPoints(journeyOrder[index]),
      })
    }
    const ordered = journeysData.sort((a, b) => {
      return journeyOrder.indexOf(a.name) > journeyOrder.indexOf(b.name)
    })
    return ordered
  }

  return (
    <div className="windrose">
      <div className="windrose__categories">
        {showCategories && (
          <>
            <p className="windrose__category-label windrose__category-label--body feature-text uppercase">
            <Trans i18nKey="windrose.body" />  
            </p>
            <p className="windrose__category-label windrose__category-label--heart feature-text uppercase">
            <Trans i18nKey="windrose.heart" />   
            </p>
            <p className="windrose__category-label windrose__category-label--mind feature-text uppercase">
            <Trans i18nKey="windrose.mind" />  
            </p>
            <p className="windrose__category-label windrose__category-label--spirit feature-text uppercase">
            <Trans i18nKey="windrose.spirit" />  
            </p>
          </>
        )}
      </div>
      <div id="windrose-container" className="windrose__container" />
    </div>
  )
}

Windrose.propTypes = {
  journeys: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  journeyOrder: PropTypes.array.isRequired,
}

export default Windrose
