import { produce } from "immer"

const sortUnitsData = (data, userData, type) => {
  if (!Array.isArray(data) || data.length === 0) {
    return []
  }

  return produce(data, draft => {
    if (
      (type === "country" && userData[type]) ||
      (type !== "country" && userData[type] && userData[type].id)
    ) {
      const userTypeUnitIndex = draft.findIndex(
        item =>
          item.id ===
          (type === "country" ? userData.country : userData[type].id)
      )
      if (userTypeUnitIndex !== -1) {
        const userTypeUnitData = draft.splice(userTypeUnitIndex, 1)[0]
        draft.sort((a, b) => a.position - b.position)
        draft.unshift(userTypeUnitData)
      } else {
        draft.sort((a, b) => a.position - b.position)
      }
    } else {
      draft.sort((a, b) => a.position - b.position)
    }
  })
}

const sortTeams = (teamsData, userData) => {
  if (!Array.isArray(teamsData) || teamsData.length === 0) {
    return []
  }

  return produce(teamsData, draft => {
    const userTeamIndex = draft.findIndex(team => team.id === userData.id)
    if (userTeamIndex > -1) {
      const userTeam = draft.splice(userTeamIndex, 1)[0]
      draft.unshift(userTeam)
    }
    draft.sort((a, b) => a.position - b.position)
  })
}

export { sortUnitsData, sortTeams }
