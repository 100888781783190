import {useTranslation} from "react-i18next";

const useCurrentLang = () => {
    const {i18n } = useTranslation()
    const [code, country] = `${i18n.language || "en-US"}`.split("-")
    return {
        language: i18n.language,
        country,
        code
    };
}
export default useCurrentLang;