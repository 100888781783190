import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { ME } from "../../gql"
import { APP_ROUTES, SCOREBOARD_FILTER } from "../../constants"
import Throbber from "../throbber"
import { useQuery } from "@apollo/react-hooks"
import { Trans } from "react-i18next"
import BusinessUnitsList from "../scoreboard/business-units-list"

const HomeLeaderBoard = () => {
  const [userData, setUserData] = useState(null)
  const { data: meData, loading } = useQuery(ME)

  useEffect(() => {
    if (meData && meData.me) {
      setUserData(meData.me)
    }
  }, [meData, loading])

  return loading ? (
    <div className="small">
      <Throbber relative loadingText="Loading..." />
    </div>
  ) : (
    <div className="small">
      <div className="for-text center">
        <h4>
          <Link to={APP_ROUTES.leaderBoard}>
            <Trans i18nKey="home-team-board.title" />
          </Link>
        </h4>
      </div>
      <div className="leader-board-body">
        <div className="leader_board_list">
          {userData && (
            <BusinessUnitsList
              limit={5}
              filter={SCOREBOARD_FILTER.BUSINESS_UNIT}
              userData={userData}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default HomeLeaderBoard
