import { useQuery } from "react-query"
import * as apiFuncs from "./common"

export const getUserProgress = ({ service, relativeUrl, params }) => {
  return useQuery(
    ["get-user-progress", { service, relativeUrl, params }],
    () => apiFuncs.apiGetFunc({ service, relativeUrl, params }),
    {
      enabled: false,
    }
  )
}

export const getUserJourneysAndActivities = ({
  service,
  relativeUrl,
  params,
}) => {
  return useQuery(
    ["get-user-journeys-and-activities", { service, relativeUrl, params }],
    () => apiFuncs.apiGetFunc({ service, relativeUrl, params }),
    {
      enabled: false,
    }
  )
}
